import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button, Typography, grid, brandColors, borderRadius, breakpointRules } from '@aceandtate/ds';
import { BUNDLE_DEAL_PERCENTAGE } from 'globalConstants';
import bundleDealMessages from 'messages/bundleDeal';
import { paths, usePath } from 'paths';

export const BundleDealBlock = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${grid[12]} ${grid[24]};
  margin: ${grid[12]} ${grid[24]};

  @media ${breakpointRules.laptop} {
    margin: ${grid[12]} ${grid[40]};
  }

  background: ${brandColors.accent10};
  color: ${brandColors.accent75};
  border: solid 1px ${brandColors.accent30};
  border-radius: ${borderRadius.small};

  p {
    * {
      font-size: inherit;
      line-height: inherit;
    }
  }

  a {
    color: ${brandColors.accent75};
    &:hover {
      color: ${brandColors.accent75};
      opacity: 0.9;
    }
  }
`;

export default function BundleDealCalloutBlock() {
  const pathTo = usePath();

  return (
    <BundleDealBlock>
      <Typography variant='h4' as='p'>
        <FormattedMessage {...bundleDealMessages.calloutBlockTitle} />
      </Typography>
      <Typography variant='bodyM'>
        <FormattedMessage
          {...bundleDealMessages.calloutBlockBody}
          values={{
            glassesLink: chunks => (
              <Button variant='link' href={pathTo(paths.glasses)}>
                {chunks}
              </Button>
            ),
            percentage: `${BUNDLE_DEAL_PERCENTAGE}%`,
            sunniesLink: chunks => (
              <Button variant='link' href={pathTo(paths.sunglasses)}>
                {chunks}
              </Button>
            )
          }}
        />
      </Typography>
    </BundleDealBlock>
  );
}
